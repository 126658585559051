
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import { Process } from '@/entities/settings'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import { fixAmountFormatted, fixPrice, fixThousands } from '@/utils/general'
  import { plainToInstance } from 'class-transformer'
  import dayjs from 'dayjs'
  import jsPDF from 'jspdf'
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import _ from 'lodash'

@Component
  export default class InspectionPdf extends GPdf {
    @Prop({ default: undefined, type: Number }) inspectionId
    parsedInspectionId: number = undefined
    @Prop({ default: undefined, type: Number }) stockId
    @Prop({ default: false, type: Boolean }) showPrices
    @Prop({ default: 'fab', type: String }) type
    @Prop({ default: 'Descargar', type: String }) buttonText

    inspectionView: Record<string, any>[] = []
    process = plainToInstance(Process, {})
    loading = false
    loadingText = ''
    carPhoto: string = ''
    disabledPdf = true
    dayjs = dayjs
    fixAmountFormatted = fixAmountFormatted

    async generatePDF () {
      this.loading = true
      this.loadingText = 'Generando PDF...'

      this.process = (await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'inspection_inspected_component' } },
        force: true,
      }))[0]

      let identifier = (await this.fetchData({
        query: { name: 'findLite', model: 'ComponentCategory' },
        filter: { name: { _eq: 'Identificación' } },
      }))[0]

      const color = identifier.components.find(component => component.name === 'Color')
      identifier = null

      let component = (await this.fetchData({
        query: { name: 'find', model: 'InspectionView' },
        filter: {
          _and: [
            { id_inspection: { _eq: this.parsedInspectionId } },
            { id_inspection_component: { _eq: color.id } },
          ],
        },
        force: true,
      }))[0]
      let fileProcess = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
            _and: [
              { id_process_record: { _eq: component?.idInspectedComponent } },
              { parameter: { process: { id: { _eq: this.process?.id } } } },
              { parameter: { name: { _eq: 'photo_inspection' } } },
            ],
          },
          force: true,
        })
      component = null

      this.carPhoto = await this.loadImage(fileProcess[0]?.file?.largeUrl)
      fileProcess = null

      const JsPDF = jsPDF
      const doc = new JsPDF('p', 'mm', 'a2', true)

      await this.getDocData()

      await doc.html(this.$refs.pdfContent)
      await doc.save(`Inspeccion ${this.parsedInspectionId}.pdf`)
      this.loading = false
    }

    getPercentages () {
      const { inspectionView } = this
      const percentageItems = inspectionView.filter(item => item.assessment?.option?.name?.includes('%'))
      this.getWheelsEquipment()

      return percentageItems.map(item => {
        let name = item.parameter.name
        if (name === ' ') {
          name = item.inspectionComponent.component.name
        }
        if (['Nivel', 'Vida útil'].includes(name)) {
          name += ` de ${item.inspectionComponent.component.name.toLowerCase()}`
        }
        return {
          name,
          value: item.assessment.option.name,
        }
      }).sort((prev, next) => next.name.length - prev.name.length)
    }

    getWheelsEquipment () {
      const wheelsEquipment = this.getEquipment()
        ?.find(item => item.title === 'Equipamiento exterior')?.fields
        ?.filter(item => ['Llantas de aleación', 'Pernos de seguridad', 'Tapas de ruedas'].includes(item.name))
        ?.sort((prev, next) => {
          let prevOrder = 0
          let nextOrder = 0

          if (prev.valueLong.toUpperCase().includes('DEBE USAR')) {
            prevOrder = 1
          } else if (prev.valueLong.toUpperCase().includes('TIENE')) {
            prevOrder = 2
          }

          if (next.valueLong.toUpperCase().includes('DEBE USAR')) {
            nextOrder = 1
          } else if (next.valueLong.toUpperCase().includes('TIENE')) {
            nextOrder = 2
          }

          return prevOrder - nextOrder
        })

      const fields = [...new Set(wheelsEquipment?.map(item => item.name))]
      return fields?.map(field => {
        const value = wheelsEquipment
          ?.filter(item => item.name === field)
          ?.map(item => item.valueLong)
          ?.join(', ')
        return {
          name: field,
          value,
        }
      })
    }

    getEquipment () {
      const equipmentItemsCategories = this.getInspectionCategoryItems('Equipamiento')
        .filter(item => item !== 'Equipamiento básico')
      return equipmentItemsCategories.map(item => {
        return {
          title: item,
          fields: this.inspectionView.filter(i => i.inspectionComponent.component.name === item)
            .map((view, index) => {
              let value = ''
              let valueLong = ''
              const question = view.inspectionComponent?.componentValue?.value
              const answer = view.assessment?.option?.name || view.qualificationValue || 'No informado'
              if (!answer.includes('%')) {
                value += `${answer}`
                valueLong += `${view.parameter.name} ${answer}`
              }
              return {
                name: question.replace('$', view.inspectionComponent?.componentValue?.value),
                value,
                valueLong,
              }
            }),
        }
      })
    }

    getBasicEquipment () {
      const { inspectionView } = this
      const basicEquipment = inspectionView
        .filter(view => view.inspectionComponent.component.name === 'Equipamiento básico')
        .sort((prev, next) => prev.inspectionComponent?.order - next.inspectionComponent?.order)
      const fields = [...new Set(basicEquipment.map(item => item.inspectionComponent.componentValue.value))]
      return fields.map(field => {
        const value = basicEquipment
          .filter(view => view.inspectionComponent.componentValue.value === field)
          .map(view => {
            let question = view.parameter.name
            if (question === '¿$?') {
              question = 'Estado:'
            }
            const answer = view.assessment?.option?.name || view.qualificationValue || 'No informado'
            return `${question} ${answer}`
          })
          .join(', ')
        return {
          name: field,
          value,
        }
      })
    }

    getInspectionCategoryItems (categoryName: string) {
      return [...new Set(
        this.inspectionView
          .filter(view => view.inspectionComponent?.component?.category?.name === categoryName)
          .sort((prev, next) => prev.inspectionComponent?.order - next.inspectionComponent?.order)
          .map(view => view.inspectionComponent?.component?.name)
      )]
    }

    getEquipmentCost (categoryName: string) {
      const { inspectionView } = this
      const categoryViews = inspectionView
        .filter(view => view.inspectionComponent?.component?.name === categoryName &&
          (view.component.supervisorCost !== null || view.component.cost !== null)
        )
        .sort((prev, next) => prev.inspectionComponent?.order - next.inspectionComponent?.order)
      const ids = [...new Set(categoryViews.map(view => view.component.id))]
      const uniques = ids.map(id => categoryViews.find(view => view.component.id === id))
      let totalCost = 0
      uniques.forEach(item => {
        totalCost += item?.component?.supervisorCost !== null ? item?.component?.supervisorCost : item?.component?.cost
      })
      return fixPrice(totalCost)
    }

    getScoresCost () {
      if (!this.docData.inspectionMetadata) return fixPrice(0)
      const categories = this.docData.inspectionMetadata
      let cost = 0
      categories?.forEach(category => {
        if (category.cost !== '') {
          cost += Number(category.cost.replaceAll('$', '').replaceAll('.', ''))
        }
      })
      return fixPrice(cost)
    }

    getCategoryCost (categoryName: string) {
      const { inspectionView } = this
      const categoryViews = inspectionView
        .filter(view => view.inspectionComponent?.component?.category?.name === categoryName &&
          (view.component.supervisorCost !== null || view.component.cost !== null)
        )
        .sort((prev, next) => prev.inspectionComponent?.order - next.inspectionComponent?.order)
      const ids = [...new Set(categoryViews.map(view => view.component.id))]
      const uniques = ids.map(id => categoryViews.find(view => view.component.id === id))
      let totalCost = 0
      uniques.forEach(item => {
        totalCost += item?.component?.supervisorCost !== null ? item?.component?.supervisorCost : item?.component?.cost
      })
      return fixPrice(totalCost)
    }

    categorizeWheels () {
      const wheelsData = this.getInspectionItems('Ruedas')
      const sufixes = [...new Set(wheelsData
        .map(item => {
          const { name } = item
          let splitted = name.split(' ')
          splitted = splitted.slice(1, splitted.length).join(' ')
          return splitted.replace('trasero', 'trasera').replace('delantero', 'delantera')
        }))]
      return sufixes.map(sufix => {
        return {
          name: `Rueda ${sufix}`,
          parts: wheelsData.filter(wheel => wheel.name.includes(sufix) || wheel.name.includes(sufix.replace('trasera', 'trasero').replace('delantera', 'delantero'))).map(part => {
            return {
              name: part.name.replace(sufix.replace('trasera', 'trasero'), '').replace(sufix.replace('delantera', 'delantero'), ''),
              value: part.value,
              cost: part.cost,
            }
          }),
        }
      })
    }

    getInspectionItems (categoryName: string, divider = 1, index = 0) {
      if (!this.inspectionView?.length) return []
      const { inspectionView } = this
      const categories = this.getInspectionCategoryItems(categoryName)
        .sort((prev, next) => prev.inspectionComponent?.order - next.inspectionComponent?.order)
      return categories
        .slice((index * categories.length / divider), (categories.length / divider) * (index + 1))
        .map(name => {
          let value = ''
          const views = inspectionView.filter(item => item.inspectionComponent?.component?.name === name)
          views
            .sort((prev, next) => prev.parameter?.order - next.parameter?.order)
            .forEach((view, index) => {
              let question = view.parameter?.name
              if (question === '¿$?') {
                question = 'Estado:'
              }
              question = question.replace(' $', '')
              if (question === '¿Funcionamiento?') {
                question = 'Funcionamiento:'
              }
              const answer = view.assessment?.option?.name || view.qualificationValue || 'No informado'
              if (!answer.includes('%')) {
                value +=
                  `${question}${question !== '' ? ' ' : ''}${answer}${index === views.length - 1 ? '' : ', '}`
              }
            })
          const view = inspectionView.find(view => view.component.inspectionComponent.component.name === name)
          return {
            name,
            value,
            cost: view?.component?.supervisorCost !== null ? view?.component?.supervisorCost : view?.component?.cost !== null ? view?.component?.cost : 0,
          }
        })
    }

    async getDocData () {
      this.getMetadata()
      this.getExecutive()
      this.getInspector()
      this.getSupervisor()
      this.getProgress()
      this.getDiscounts()
      this.getInspectionMetadata()
      await this.getPhotos()
      await this.getCarData()
      await this.getSystem()
      await this.getMaintenances()
      await this.getDocumentation()
    }

    getDiscounts () {
      const view = this.inspectionView[0]
      const supervisorMetadata = view.supervisorMetadata
      const inspectorMetadata = view.inspectorMetadata

      const supervisorCategories = supervisorMetadata?.categories || []
      let validatedDiscount = 0

      supervisorCategories?.forEach(category => {
        validatedDiscount += category.supervisorCost ? Number(category.supervisorCost) : 0
      })

      const inspectorCategories = inspectorMetadata?.categories || []
      let unvalidatedDiscount = 0

      inspectorCategories.forEach(category => {
        unvalidatedDiscount += category.inspectorCost ? Number(category.inspectorCost) : 0
      })
      this.$set(this.docData, 'discounts', {
        validated: validatedDiscount,
        unvalidated: unvalidatedDiscount,
        authorized: supervisorMetadata?.authorizedAmount || 'No informado',
      })
    }

    getProgress () {
      const view = this.inspectionView[0]
      const supervisorMetadata = view.supervisorMetadata
      const inspectorMetadata = view.inspectorMetadata

      const inspectorValidated = inspectorMetadata?.categories.filter(category => category.inspectorValidated)
      const supervisorValidated = supervisorMetadata?.categories?.filter(category => category.validated)

      const inspectorProgress = ((inspectorValidated?.length || 0) / (inspectorMetadata?.totalInspectedComponents || 1)) * 100
      const supervisorProgress = ((supervisorValidated?.length || 0) / (supervisorMetadata?.totalInspectedComponents || 1)) * 100

      this.$set(this.docData, 'progress', {
        inspector: `${Math.round(inspectorProgress)}%`,
        supervisor: `${Math.round(supervisorProgress)}%`,
      })
    }

    getInspectionMetadata () {
      const supervisorMetadata = this.inspectionView[0].supervisorMetadata
      const inspectorMetadata = this.inspectionView[0].inspectorMetadata
      this.$set(this.docData, 'comments', {
        inspector: supervisorMetadata?.commentInspector || inspectorMetadata?.commentInspector,
        supervisor: supervisorMetadata?.comment || inspectorMetadata?.comment,
      })
      const supervisorCategories = supervisorMetadata?.categories
      const inspectorCategories = inspectorMetadata?.categories

      const mergedCategories = supervisorCategories || []
      inspectorCategories?.forEach(category => {
        if (!mergedCategories?.map(item => item.name)?.includes(category.name)) {
          mergedCategories?.push(category)
        }
      })

      mergedCategories.forEach((category, index) => {
        if (category.score === null) {
          mergedCategories[index].score = inspectorCategories?.find(item => item.name === category.name).score
        }
        if (category.supervisorCost === null) {
          mergedCategories[index].supervisorCost = inspectorCategories?.find(item => item.name === category.name).supervisorCost
        }
        if (category.inspectorCost === null) {
          mergedCategories[index].inspectorCost = inspectorCategories?.find(item => item.name === category.name).inspectorCost
        }
      })

      this.$set(this.docData, 'inspectionMetadata', mergedCategories
        .map(category => {
          let cost = ''
          if (category.supervisorCost !== null && category.supervisorCost !== undefined) {
            cost = fixPrice(category.supervisorCost)
          } else {
            if (category.inspectorCost !== null && category.inspectorCost !== undefined) {
              cost = fixPrice(category.inspectorCost)
            }
          }

          return {
            name: category.name,
            value: category.score !== null ? category.score : (category.inspectorValidated ? 'Validado' : 'Sin validar'),
            cost,
            order: category.name,
          }
        }).sort((prev, next) => prev?.order - next?.order))
    }

    async getMaintenances () {
      const { inspectionView } = this
      const view = inspectionView[0]
      const idAuto = view.auto.id

      const maintenances = await this.fetchData({
        query: { name: 'find', model: 'Maintenance' },
        filter: { id_auto: { _eq: idAuto } },
      })

      this.$set(this.docData, 'maintenances', maintenances)
    }

    async getPhotos () {
      const inspectionView = _.cloneDeep(this.inspectionView)
      let noRepeats = []
      this.loadingText = 'Descargando fotos...'
      inspectionView.forEach(view => {
        if (!noRepeats.map(item => item.inspectionComponent.component.id).includes(view.inspectionComponent.component.id)) {
          noRepeats.push(view)
        }
      })
      noRepeats = noRepeats.sort((prev, next) => prev.inspectionComponent.component.id - next.inspectionComponent.component.id)
      const photosFileParameter = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _in: noRepeats.map(item => item.idInspectedComponent) } },
            { parameter: { process: { id: { _eq: this.process?.id } } } },
            { parameter: { name: { _eq: 'photo_inspection' } } },
          ],
        },
      })
      const photos = await Promise.all(photosFileParameter.map(async (fp, index) => {
        return {
          name: noRepeats.find(item => item.idInspectedComponent === fp.idProcessRecord).inspectionComponent.component.name,
          link: await this.loadImage(fp.file.metadata.m.uri),
        }
      }))
      this.$set(this.docData, 'photos', this.chunkArray(photos.filter(item => item), 20))
    }

    chunkArray (array, chunkSize) {
      const numberOfChunks = Math.ceil(array.length / chunkSize)
      return [...Array(numberOfChunks)]
        .map((value, index) => {
          return array.slice(index * chunkSize, (index + 1) * chunkSize)
        })
    }

    getExecutive () {
      const { capitalizeFirstLetter } = this
      const executive = this.inspectionView[0].executive?.person

      this.$set(this.docData, 'executive', {
        fullName: executive ? `${capitalizeFirstLetter(executive?.firstName.trim())} ${capitalizeFirstLetter(executive?.surname.trim())}` : 'No informado',
        phone: executive?.phone.work || 'Teléfono no informado',
        email: executive?.email.work || 'Email no informado',
        uid: executive?.uid,
      })
    }

    getBarColor (percentage, translucent = false) {
      percentage = Number(percentage.replace('%', ''))
      const colors = translucent ? [
        {
          threshold: 25,
          color: '#f9aeae', // red
        },
        {
          threshold: 50,
          color: '#ffd9ad', // orange
        },
        {
          threshold: 75,
          color: '#feed7b', // yellow
        },
        {
          threshold: 99,
          color: '#6ef2a9', // green
        },
        {
          threshold: 101,
          color: '#c9d6f3', // blue
        },
      ] : [
        {
          threshold: 25,
          color: '#F03738', // red
        },
        {
          threshold: 50,
          color: '#FF9E2C', // orange
        },
        {
          threshold: 75,
          color: '#FAD901', // yellow
        },
        {
          threshold: 99,
          color: '#13CE66', // green
        },
        {
          threshold: 101,
          color: '#6185DB', // blue
        },
      ]

      let returnColor = colors[0].color
      if (percentage >= 100) {
        returnColor = colors[colors.length - 1].color
      }

      if (!percentage) return returnColor

      colors
        .sort((prev, next) => {
          return next.threshold - prev.threshold
        })
        .forEach(color => {
          if (percentage < color.threshold) {
            returnColor = color.color
          }
        })

      return returnColor
    }

    getInspector () {
      const { capitalizeFirstLetter } = this
      const inspector = this.inspectionView[0].inspector?.person

      this.$set(this.docData, 'inspector', {
        fullName: inspector ? `${capitalizeFirstLetter(inspector?.firstName.trim())} ${capitalizeFirstLetter(inspector?.surname.trim())}` : 'No informado',
        phone: inspector?.phone.work || 'Teléfono no informado',
        email: inspector?.email.work || 'Email no informado',
        uid: inspector?.uid,
        qualification: this.inspectionView[0]?.inspectorQualification,
      })
    }

    getSupervisor () {
      const { capitalizeFirstLetter } = this
      const supervisor = this.inspectionView[0].supervisor?.person

      this.$set(this.docData, 'supervisor', {
        fullName: supervisor ? `${capitalizeFirstLetter(supervisor?.firstName.trim())} ${capitalizeFirstLetter(supervisor?.surname.trim())}` : 'No informado',
        phone: supervisor?.phoneWork || 'Teléfono no informado',
        email: supervisor?.uid,
        qualification: this.inspectionView[0].supervisorQualification || 0,
      })
    }

    async getDocumentation () {
      const category = (await this.fetchData({
        query: { name: 'find', model: 'ComponentCategory' },
        filter: { name: { _eq: 'Documentación' } },
      }))[0]

      const soap = this.findComponentBySlug(category.components, 'soap')
      const technicalReview = this.findComponentBySlug(category.components, 'technical_review')
      const circulationPermit = this.findComponentBySlug(category.components, 'circulation_permit')

      const soapInspection = this.inspectionView.filter(view => view.inspectionComponent.id === soap.inspectionComponents[0].id)
      const techRevInspection = this.inspectionView.filter(view => view.inspectionComponent.id === technicalReview.inspectionComponents[0].id)
      const circPermitInspection = this.inspectionView.filter(view => view.inspectionComponent.id === circulationPermit.inspectionComponents[0].id)

      this.$set(this.docData, 'documentation', {
        circulationPermit: {
          company: circPermitInspection.find(i => i.parameter.name === 'Comuna')?.qualificationValue,
          expiracy: dayjs(this.formatDate(circPermitInspection.find(i => i.parameter.name === 'Fecha de vencimiento')?.qualificationValue)).format('DD/MM/ YYYY'),
          cost: fixPrice(circPermitInspection?.[0]?.component?.cost || 0),
        },
        technicalReview: {
          company: techRevInspection.find(i => i.parameter.name === 'Planta de revisión técnica')?.qualificationValue,
          expiracy: dayjs(this.formatDate(techRevInspection.find(i => i.parameter.name === 'Fecha de vencimiento')?.qualificationValue)).format('DD/MM/ YYYY'),
          cost: fixPrice(techRevInspection?.[0]?.component?.cost || 0),
        },
        soap: {
          company: soapInspection.find(i => i.parameter.name === 'Aseguradora')?.qualificationValue,
          expiracy: dayjs(this.formatDate(soapInspection.find(i => i.parameter.name === 'Fecha de vencimiento')?.qualificationValue)).format('DD/MM/ YYYY'),
          cost: fixPrice(soapInspection?.[0]?.component?.cost || 0),
        },
      })
    }

    getMetadata () {
      const date = dayjs(this.inspectionView[0]?.date).format('DD /MM /YYYY')
      const id = `${dayjs(this.inspectionView[0]?.date).format('YYYY')}${'0'.repeat(5 - String(this.parsedInspectionId).length)}${this.parsedInspectionId}`

      this.$set(this.docData, 'metadata', {
        id,
        date,
      })
    }

    async getCarData () {
      const auto = this.inspectionView[0].auto
      const process = (await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'inspection' } },
        force: true,
      }))[0]
      const appraisalProcess = (await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'appraisal' } },
        force: true,
      }))[0]
      const dealAttributes = (await this.fetchData({
        query: { name: 'find', model: 'DealAutoAttribute' },
        filter: {
          _and: [
            { component: { slug: { _in: ['chassis_serial', 'engine_serial'] } } },
            { id_process: { _eq: appraisalProcess.id } },
            { id_process_record: { _eq: this.inspectionView[0].idAppraisal } },
          ],
        },
      }))
      const component = (await this.fetchData({
        query: { name: 'find', model: 'Component' },
        filter: { slug: { _eq: 'mileage' } },
        force: true,
      }))[0]
      const mileage = (await this.fetchData({
        query: { name: 'find', model: 'AttributeView' },
        filter: {
          _and: [
            { id_process: { _eq: process.id } },
            { id_process_record: { _eq: this.parsedInspectionId } },
            { id_component: { _eq: component.id } },
          ],
        },
      }))[0]

      this.$set(this.docData, 'car', {
        plate: auto.registrationPlate,
        brand: auto.version.version.model.brand.name,
        model: `${auto.version.version.model.name}  ${auto.version.version.name}`,
        year: auto.version.year.id,
        mileage: mileage?.value ? fixThousands(mileage.value) : 'NO INFORMADO',
        chassisSerial: dealAttributes?.find(attribute => attribute.component.slug === 'chassis_serial')?.value,
        engineSerial: dealAttributes?.find(attribute => attribute.component.slug === 'engine_serial')?.value,
      })
    }

    splitPlate (plate: string) {
      return [plate?.slice(0, 2), plate?.slice(2, 4), plate?.slice(4, 6)]
    }

    findComponentBySlug (inspectionComponents, slug) {
      return inspectionComponents?.find(component => component.slug === slug)
    }

    findComponentInfo (component, inspection) {
      const matchingInspectedComponent = inspection?.inspectedComponents?.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (!matchingInspectedComponent) return
      const date = DateGenerator.findGeneratedDate(matchingInspectedComponent.findInspectionParameterByOrder(2).value).internal

      const name = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.value

      return { date, name }
    }

  @Watch('stockId', { immediate: true })
  async updateWithStock () {
    if (this.stockId && !this.inspectionId) {
      const view = (await this.fetchData({
        query: { name: 'find', model: 'InspectionView' },
        filter: { id_stock: { _eq: this.stockId } },
        force: true,
      }))[0]

      this.parsedInspectionId = view?.idInspection
      await this.update(this.parsedInspectionId || this.inspectionId)
    }
  }

  @Watch('inspectionId', { immediate: true })
  // @Debounce()
  async update (val) {
    if (!val) return
      this.parsedInspectionId = val
      this.inspectionView = (await this.fetchData({
        query: { name: 'findLite', model: 'InspectionView' },
        filter: { id_inspection: { _eq: this.parsedInspectionId } },
        force: true,
      })).sort((prev, next) => prev.inspectionComponent?.order - next.inspectionComponent?.order)
      this.disabledPdf = !this.inspectionView.some(view => (view.assessment?.option?.name || view.qualificationValue)) ||
        this.inspectionView.some(view => !view.date)
    }
  }

