
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import { Inspection } from '@/entities/purchase'
  import BodyWorkSupervisorPanel from '@/components/forms/inspection/supervisor/panel/BodyWorkSupervisorPanel.vue'
  import ElectricSupervisorPanel from '@/components/forms/inspection/supervisor/panel/ElectricSupervisorPanel.vue'
  import MechanicalSupervisorPanel from '@/components/forms/inspection/supervisor/panel/MechanicalSupervisorPanel.vue'
  import TireAndWheelSupervisorPanel from '@/components/forms/inspection/supervisor/panel/TireAndWheelSupervisorPanel.vue'
  import InsideSupervisorPanel from '@/components/forms/inspection/supervisor/panel/InsideSupervisorPanel.vue'
  import SecurityEquipmentSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/SecurityEquipmentSupervisorPanel.vue'
  import OpticSupervisorPanel from '@/components/forms/inspection/supervisor/panel/OpticSupervisorPanel.vue'
  import ComfortEquipmentSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/ComfortEquipmentSupervisorPanel.vue'
  import ExteriorEquipmentSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/ExteriorEquipmentSupervisorPanel.vue'
  import EquipmentSupervisorPanel from '@/components/forms/inspection/supervisor/panel/EquipmentSupervisorPanel.vue'
  import RearviewMirrorSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/RearviewMirrorSupervisorPanel.vue'
  import MaintenanceSupervisorPanel from '@/components/forms/inspection/supervisor/panel/MaintenanceSupervisorPanel.vue'
  import IdentifierSupervisorPanel from '@/components/forms/inspection/supervisor/panel/IdentifierSupervisorPanel.vue'
  import DocumentationSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/DocumentationSupervisorPanel.vue'
  import PhotosReportPanel from '@/components/forms/inspection/report/PhotosReportPanel.vue'
  import MaintenanceSupervisorPanelSummary
    from '@/components/forms/inspection/panels/MaintenanceSupervisorPanelSummary.vue'
  import BodyWorkSupervisorPanelSummary from '@/components/forms/inspection/panels/BodyWorkSupervisorPanelSummary.vue'
  import OpticSupervisorPanelSummary from '@/components/forms/inspection/panels/OpticSupervisorPanelSummary.vue'
  import RearviewMirrorSupervisorPanelSummary
    from '@/components/forms/inspection/panels/RearviewMirrorSupervisorPanelSummary.vue'
  import TireAndWheelSupervisorPanelSummary
    from '@/components/forms/inspection/panels/TireAndWheelSupervisorPanelSummary.vue'
  import ElectricSupervisorPanelSummary from '@/components/forms/inspection/panels/ElectricSupervisorPanelSummary.vue'
  import InsideSupervisorPanelSummary from '@/components/forms/inspection/panels/InsideSupervisorPanelSummary.vue'
  import MechanicalSupervisorPanelSummary from '@/components/forms/inspection/panels/MechanicalSupervisorPanelSummary.vue'
  import EquipmentSupervisorPanelSummary from '@/components/forms/inspection/panels/EquipmentSupervisorPanelSummary.vue'
  import PhotosSupervisorPanelSummary from '@/components/forms/inspection/panels/PhotosSupervisorPanelSummary.vue'
  import ComfortEquipmentSupervisorPanelSummary
    from '@/components/forms/inspection/panels/ComfortEquipmentSupervisorPanelSummary.vue'
  import SecurityEquipmentSupervisorPanelSummary
    from '@/components/forms/inspection/panels/SecurityEquipmentSupervisorPanelSummary.vue'
  import ExteriorEquipmentSupervisorPanelSummary
    from '@/components/forms/inspection/panels/ExteriorEquipmentSupervisorPanelSummary.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'

@Component({
  components: {
    RatingCell,
    ExteriorEquipmentSupervisorPanelSummary,
    SecurityEquipmentSupervisorPanelSummary,
    ComfortEquipmentSupervisorPanelSummary,
    PhotosSupervisorPanelSummary,
    EquipmentSupervisorPanelSummary,
    MechanicalSupervisorPanelSummary,
    InsideSupervisorPanelSummary,
    ElectricSupervisorPanelSummary,
    TireAndWheelSupervisorPanelSummary,
    RearviewMirrorSupervisorPanelSummary,
    OpticSupervisorPanelSummary,
    BodyWorkSupervisorPanelSummary,
    MaintenanceSupervisorPanelSummary,
    PhotosReportPanel,
    DocumentationSupervisorPanel,
    IdentifierSupervisorPanel,
    MaintenanceSupervisorPanel,
    RearviewMirrorSupervisorPanel,
    EquipmentSupervisorPanel,
    ExteriorEquipmentSupervisorPanel,
    ComfortEquipmentSupervisorPanel,
    OpticSupervisorPanel,
    SecurityEquipmentSupervisorPanel,
    InsideSupervisorPanel,
    TireAndWheelSupervisorPanel,
    MechanicalSupervisorPanel,
    ElectricSupervisorPanel,
    BodyWorkSupervisorPanel,
  },
})
  export default class InspectionSummaryView extends GForm {
  inspection: Inspection = null
  idProcess = null
  loading = true
  generation = null
  idProcessInspection = null
  fileParameters = null
  overCategory = null
  photo = null
  form = {
    identifier: {
      panels: 0,
    },
    documentation: {
      panels: -1,
    },
    maintenance: {
      panels: -1,
    },
    bodywork: {
      panels: -1,
    },
    spotlightOptic: {
      panels: -1,
    },
    rearviewMirror: {
      panels: -1,
    },
    tireAndWheel: {
      panels: -1,
    },
    inside: {
      panels: -1,
    },
    electric: {
      panels: -1,
    },
    mechanical: {
      panels: -1,
    },
    equipment: {
      panels: -1,
    },
    comfortEquipment: {
      panels: -1,
    },
    securityEquipment: {
      panels: -1,
    },
    externalEquipment: {
      panels: -1,
    },
    photos: {
      panels: -1,
    },
  }

  componentCategories: Record<string, any> = {
    identifier: null,
    documentation: null,
    maintenance: null,
    bodywork: null,
    spotlightOptic: null,
    rearviewMirror: null,
    tireAndWheel: null,
    inside: null,
    electric: null,
    mechanical: null,
    equipment: null,
  }

  photoProperties = {
    properties: {
      accept: null,
      multiple: false,
      fileTypeId: null,
      name: '',
    },
  }

  async mounted () {
    const { id } = this

    await this.getInfo(id)
    await this.viewInspectionStatus()
    await this.setCategories()

    this.fileParameters = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })
    await this.getPhoto(this.fileParameters, id)
    this.loading = false
  }

  async getPhoto (fileParameters, id) {
    const parameter = fileParameters.find(fp => fp.name === 'front_pilot')

    const photo = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { id_file_parameter: { _eq: parameter.id } },
        ],
      },
      force: true,
    })

    this.photo = photo[0]
  }

  async getInfo (id) {
    this.inspection = await this.fetchData({ query: { name: 'fetch', model: 'Inspection', params: { id } }, force: true })

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })

    this.idProcess = process?.[0]?.id

    const processInspection = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
    })

    this.idProcessInspection = processInspection?.[0]?.id
    await this.viewInspectionStatus()
  }

  async setCategories () {
    const identifier = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Identificación' } },
    })

    const bodywork = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Carrocería' } },
    })

    const rearviewMirror = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Lunetas' } },
    })

    const optic = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Focos y ópticos' } },
    })

    const tireAndWheel = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Ruedas' } },
    })

    const maintenance = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Mantenimiento' } },
    })

    const inside = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Interior' } },
    })

    const mechanical = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Mecánica' } },
    })

    const documentation = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Documentación' } },
    })

    const electric = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Eléctrica' } },
    })

    const equipment = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Equipamiento' } },
    })

    this.componentCategories = {
      identifier: identifier?.[0],
      bodywork: bodywork?.[0],
      rearviewMirror: rearviewMirror?.[0],
      spotlightOptic: optic?.[0],
      tireAndWheel: tireAndWheel?.[0],
      mechanical: mechanical?.[0],
      maintenance: maintenance?.[0],
      inside: inside?.[0],
      documentation: documentation?.[0],
      electric: electric?.[0],
      equipment: equipment?.[0],
    }
  }

  changePanel (panel) {
    for (const key in this.form) {
      if (key !== panel) {
        this.form[key].panels = -1
      }
    }
  }

  closePanels () {
    for (const key in this.form) {
      this.form[key].panels = -1
    }
  }

  async viewInspectionStatus () {
    const { generation } = this
    if (!generation) {
      this.generation = await this.getAutoGeneration(this.inspection.auto)
      if (!this.generation?.id) {
        this.generation = { attributes: this.inspection.auto?.version?.attributes }
      }
    }
  }

  async getAutoGeneration (auto) {
    if (!auto?.generation?.id) return this.findAlternativesAttributes(auto)

    return this.fetchData({
      query: { name: 'fetch', params: { id: auto.generation.id }, model: 'Generation' },
    })
  }

  async findAlternativesAttributes (auto) {
    const generations = await this.fetchData({
      query: { name: 'find', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: auto.version.version.id }, id_year: { _eq: auto.version.year.id - 1 } } },
    })

    return generations[0]
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionChange (val) {
    if (!val?.id) return
    this.overCategory = val?.metadata?.overCategory
  }

  get isNotQualify () {
    const { inspection } = this
    if (!inspection) return false

    return inspection?.status.isClosed && inspection?.closingReason?.name === 'not_qualify'
  }
  }
