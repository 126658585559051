
  import { Component, Prop } from 'vue-property-decorator'
  import Cell from '@/components/dataTables/cell/cell'
  import InspectionSummaryView from '@/components/dataTables/inspection/InspectionSummaryView.vue'
  import { capitalize, fixPrice } from '@/utils/general'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import InspectionPdf from '@/components/forms/inspection/report/InspectionPDF.vue'
  import { Entity } from '@/entities'
  import { ExpandCellOptions } from '.'
  import { Stock } from '@/entities/sales'

@Component({
  methods: { fixPrice, capitalize },
  components: { GAlert, InspectionSummaryView, InspectionPdf },
})
  export default class InspectionPdfCell extends Cell {
  declare item: Entity
  declare options: ExpandCellOptions

  @Prop({ default: undefined, type: Stock }) stock

  get itemIsStock () {
    const { item } = this

    return item instanceof Stock
  }
  }
