
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  methods: { isValidNumber },
  components: { RowData, RatingCell, GFiles, GFormSlot, BasePanel },
})
  export default class InsideSupervisorPanel extends BaseStepper {
  componentKeys = ['carCabin', 'carInteriorRoof', 'carTrunk', 'accessories', 'leftSeat', 'rightSeat']

  fieldsKeys = ['status']
  total = null
  validation = false

  formData = {
    carCabin: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    carInteriorRoof: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    carTrunk: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    accessories: {
      status: {
        answer: null,
        provisional: false,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    leftSeat: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rightSeat: {
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    passengerSeat: {
      present: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    thirdRowSeat: {
      present: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    carCabin: '',
    carInteriorRoof: '',
    carTrunk: '',
    accessories: '',
    leftSeat: '',
    rightSeat: '',
    passengerSeat: '',
    thirdRowSeat: '',
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get statusItems () {
    const {
      carCabin,
      carInteriorRoof,
      carTrunk,
      accessories,
      leftSeat,
      rightSeat,
      passengerSeat,
      thirdRowSeat,
    } = this

    return {
      carCabin: carCabin?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      carInteriorRoof: carInteriorRoof?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      carTrunk: carTrunk?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      accessories: accessories?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      leftSeat: leftSeat?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      rightSeat: rightSeat?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      passengerSeat: passengerSeat?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
      thirdRowSeat: thirdRowSeat?.findQuestion(1)?.inspectionAssessments?.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      carCabin,
      carInteriorRoof,
      carTrunk,
      accessories,
      leftSeat,
      rightSeat,
      passengerSeat,
      thirdRowSeat,
    } = this

    return {
      carCabin: this.getQuestionOptions(carCabin, 1)?.find(item => item.score === 0),
      carInteriorRoof: this.getQuestionOptions(carInteriorRoof, 1)?.find(item => item.score === 0),
      carTrunk: this.getQuestionOptions(carTrunk, 1)?.find(item => item.score === 0),
      accessories: this.getQuestionOptions(accessories, 1)?.find(item => item.score === 0),
      leftSeat: this.getQuestionOptions(leftSeat, 1)?.find(item => item.score === 0),
      rightSeat: this.getQuestionOptions(rightSeat, 1)?.find(item => item.score === 0),
      passengerSeat: this.getQuestionOptions(passengerSeat, 1)?.find(item => item.score === 0),
      thirdRowSeat: this.getQuestionOptions(thirdRowSeat, 1)?.find(item => item.score === 0),
    }
  }

  get thirdRowSeat () {
    return this.findComponentBySlug(this.components, 'third_row_seat')
  }

  get passengerSeat () {
    return this.findComponentBySlug(this.components, 'passenger_seat')
  }

  get rightSeat () {
    return this.findComponentBySlug(this.components, 'right_seat')
  }

  get leftSeat () {
    return this.findComponentBySlug(this.components, 'left_seat')
  }

  get accessories () {
    return this.findComponentBySlug(this.components, 'accessories')
  }

  get carTrunk () {
    return this.findComponentBySlug(this.components, 'car_trunk')
  }

  get carCabin () {
    return this.findComponentBySlug(this.components, 'car_cabin')
  }

  get carInteriorRoof () {
    return this.findComponentBySlug(this.components, 'car_interior_roof')
  }

  setErrorMessage (key, field) {
    if (field === 'status' && (this.formData[key][field].provisional === null || this.formData[key][field].provisional === undefined)) {
      this.errorIdentifierMessage[key].provisional = 'Este campo es requerido'
    } else if (field === 'status' && (this.formData[key][field].provisional !== null || this.formData[key][field].provisional !== undefined)) {
      this.errorIdentifierMessage[key].provisional = ''
    }

    this.errorIdentifierMessage[key][field] = 'Este campo es requerido'
  }

  async send () {
    const { allComponents, inspection, categoryName, category, formData } = this

    let error = false
    const keys = Object.keys(allComponents)

    for (const key of keys) {
      if (formData?.[key]?.cost && (formData[key].supervisorCost === null || formData[key].supervisorCost === undefined)) {
        this.errorIdentifierMessage[key] = 'El costo es requerido'
        error = true
      } else {
        this.errorIdentifierMessage[key] = ''
      }
    }
    if (error) return

    await Promise.all(keys.map(async key => {
      const component = allComponents[key]
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)
        if (formData?.[key]?.supervisorCost) {
          await this.pushData({
            model: 'InspectionInspectedComponent',
            fields: { id: matchingInspectedComponent.id, supervisor_cost: formData[key].supervisorCost || 0 },
          })
        }
      }
    }))

    this.total = this.getTotalCost(this.formData, 'supervisorCost')

    this.$emit('input', { categoryName, category, total: this.total })
    this.$emit('inspection-category-done')
  }

  get allComponents () {
    const {
      carCabin,
      carInteriorRoof,
      carTrunk,
      accessories,
      leftSeat,
      rightSeat,
      passengerSeat,
      thirdRowSeat,
      selfPanel,
      componentKeys,
    } = this

    return {
      carCabin,
      carInteriorRoof,
      carTrunk,
      accessories,
      leftSeat,
      rightSeat,
      passengerSeat,
      thirdRowSeat,
      selfPanel,
      componentKeys,
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification } = this
    this.step = 1
    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        if (key === 'passengerSeat' || key === 'thirdRowSeat') {
          this.formData[key].present.answer = matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.id
          if (!matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.score) {
            this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.id
            this.formData[key].status.answer = null
          } else {
            this.formData[key].status.provisional = false
            this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(2).map(item => item.assessment.id)
          }
        } else {
          if (!matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.score) {
            this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(1)?.assessment?.id
            this.formData[key].status.answer = null
          } else {
            this.formData[key].status.provisional = false
            this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(1).map(item => item.assessment.id)
          }
        }

        this.formData[key].cost = matchingInspectedComponent.cost
        this.formData[key].supervisorCost = isValidNumber(matchingInspectedComponent.supervisorCost) ? matchingInspectedComponent.supervisorCost : matchingInspectedComponent.cost
      }
    }

    const watchFields = [...componentKeys, 'passengerSeat', 'thirdRowSeat']

    for (const [index, key] of watchFields.entries()) {
      await handleComponentChange(val[key], index + 1, key)
    }
  }

  get bodyWorkIsAllowed () {
    const { generation } = this

    if (!generation) return undefined

    return generation?.attributes?.find(item => item.val.toUpperCase() === 'SUV' || item.val.toUpperCase() === 'MINIBUS')
  }

  get isDoorMoreThan4 () {
    const { generation } = this

    if (!generation) return undefined
    const attribute = generation?.attributes?.find(item => item.slug.toUpperCase() === 'DOORS')

    const isMore = parseInt(attribute?.val) > 3

    if (isMore) {
      this.formData.passengerSeat.present.answer = this.questionPassengerYes?.id
    }

    return isMore
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get questionPassengerYes () {
    const { getQuestionOptionScoreZero, allComponents } = this
    if (!allComponents.passengerSeat) return

    return getQuestionOptionScoreZero(allComponents.passengerSeat, 1).find(item => item.name === 'Si')
  }

  get questionPassengerNo () {
    const { getQuestionOptionScoreZero, allComponents } = this

    return getQuestionOptionScoreZero(allComponents.passengerSeat, 1).find(item => item.name === 'No')
  }

  get questionThirdRowSeatYes () {
    const { getQuestionOptionScoreZero, allComponents } = this

    return getQuestionOptionScoreZero(allComponents.thirdRowSeat, 1)?.find(item => item.name === 'Si')
  }

  get questionThirdRowSeatNo () {
    const { getQuestionOptionScoreZero, allComponents } = this

    return getQuestionOptionScoreZero(allComponents.thirdRowSeat, 1)?.find(item => item.name === 'No')
  }

  get watchDataChange () {
    const { allComponents, formData: { thirdRowSeat: { present: { answer } } } } = this

    return {
      allComponents,
      answer,
    }
  }

  @Watch('watchDataChange', { immediate: true, deep: true })
  onResponseChange (val) {
    if (val.answer === this.questionThirdRowSeatNo?.id) {
      this.formData.thirdRowSeat.status.provisional = this.getQuestionOptionScoreZero(val.allComponents.thirdRowSeat, 2)[0].id
    }
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    if (!val?.id) return
    const { category } = this

    if (!isValidNumber(this.total)) {
      const totalSupervisor = val.supervisorMetadata?.findCategoryByName(category.name)?.total
      const totalInspector = val.metadata?.findCategoryByName(category.name)?.total
      this.total = isValidNumber(totalSupervisor) ? totalSupervisor : totalInspector
    }
  }

  getName (name, resp) {
    return name === 'Accesorios' ? this.getQuestionValue(Array.isArray(resp)) : 'Estado'
  }

  getQuestionValue (val) {
    return !val ? '¿Se encuentran todos los accesorios?' : 'Faltantes'
  }
  }
